<template>
  <v-row>
    <v-col cols="12" v-if="editing" class="pt-0">
      <v-card flat>
        <v-card-title v-text="title" class="text-overline px-0" />

        <v-card-text class="pa-0">
          <v-row>
            <!-- Username -->
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                dense
                required
                label="Username"
                hide-details="auto"
                v-model="editing.username"
                :disabled="loading"
                :error-messages="usernameErrors"
                @input="$v.editing.username.$touch()"
                @blur="$v.editing.username.$touch()"
              />
            </v-col>

            <!-- E-mail -->
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                dense
                required
                label="E-mail"
                type="email"
                hide-details="auto"
                v-model="editing.email"
                :disabled="loading"
                :error-messages="emailErrors"
                @input="$v.editing.email.$touch()"
                @blur="$v.editing.email.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'pibot-form-block-profile',
  validations: {
    editing: {
      username: { required },
      email: { required, email }
    }
  },
  data: () => ({
    title: 'Profile'
  }),
  computed: {
    ...mapState({
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    }),
    usernameErrors () {
      const errors = []
      if (!this.$v.editing.username.$dirty) return errors

      !this.$v.editing.username.required && errors.push('Username is required.')

      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.editing.email.$dirty) return errors

      !this.$v.editing.email.email && errors.push('Must be a valid e-mail address.')
      !this.$v.editing.email.required && errors.push('E-mail is required.')

      return errors
    }
  },
  methods: {
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    }
  }
}
</script>
